import React from "react";
import classes from "./style.module.css";
import { AutoCompleteLocationProps } from "./types";

const AutoCompleteLocation: React.FunctionComponent<
  AutoCompleteLocationProps
> = ({ isActive, locationData, onClick, icon }) => {
  const getLocation = () => {
    onClick(locationData);
  };
  return (
    <div
      className={`${classes.location_container} ${
        isActive ? classes.isActive : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        getLocation();
      }}
    >
      {icon}
      {locationData?.displayLines.join(" ")}
    </div>
  );
};

export default AutoCompleteLocation;
